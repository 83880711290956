@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

html,
body {
  font-size: 17px;
}

#headerWrapper {
  padding: 10px;
  background: #460e30;
  margin: 0;
}

.st0 {
    fill: #F2F2F2;
    ;
  }

#header {
  max-width: 984px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  font-family: trade-gothic-next, sans-serif;
}

#printLogo {
  display: none;
}

#logoBox {
  display: flex;
  align-items: center;
}

#donate {
  display: flex;
  justify-content: center;
}

#donate>a>button {
  cursor: pointer !important;
  font-family: trade-gothic-next, sans-serif;
  color: white;
  background: #76297c;
  border: none;
  border-radius: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0.3rem 2.5rem 0.4rem 2.5rem;
  transition: 0.2s;
}

#donate a {
  transition: 0.2s;
}

#donate a:hover {
  text-decoration: none !important;
}

#donate a:hover button {
  transform: scale3d(1.05, 1.05, 1.05);
}

#navWrapper {
  background: #460e30;
  color: white;
  margin: 0;
  font-size: 0.9em;
  z-index: 999;
  font-family: trade-gothic-next, sans-serif !important;
}

.dropbtn a {
  color: #c5c5c5;
  transition: 0.2s;
}

.dropdown a {
  color: #fff;
}

.dropdown li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
  font-size: 0.9em;
}

#navWrapper li:hover>a {
  color: #fff !important;
}

#mainNav {
  max-width: 984px;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-box-pack: center;
  -moz-box-align: center;
  box-align: center;
}

ul.primary-nav {
  display: flex;
  margin: 0;
  width: 984px;
  padding: 0;
  list-style-type: none;
  max-width: 100%;
}

ul.primary-nav li {
  display: block;
  flex: 1 1 0px;
  text-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  padding: 0;
}

#forResearchers {
  border-bottom: 5px solid #ed8033;
}

#forResearchers .section__item a:hover {
  background: #dc620b;
}

#forThePublic {
  border-bottom: 5px solid #00a5c9;
}

#forThePublic .section__item a:hover {
  background: #008aa9;
}

#forLibrariesAndEducators {
  border-bottom: 5px solid #bc0b36;
}

#forLibrariesAndEducators .section__item a:hover {
  background: #a2042a;
}

#forLibrariesAndStateAgencies {
  border-bottom: 5px solid #771e7e;
}

#forLibrariesAndStateAgencies .section__item a:hover {
  background: #58115f;
}

ul.primary-nav li a {
  padding: 10px 10px;
  display: block;
  text-decoration: none;
  text-align: center;
}

.section__item a {
  text-align: left !important;
}

.searchBox {
  transform: translateY(2px);
}

.searchBox__input {
  background-color: #460e30;
  border: 0;
  border-radius: 0px;
  border-bottom: 2px solid #f2f2f2;
  line-height: 18px;
  font-size: 16px;
  color: #ffffff;
  padding: 4px;
  transition: border-color 0.1s linear;
  width: 240px;
  box-sizing: border-box;
}

.searchBox__input:not(:placeholder-shown) {
  border-color: white;
}

.searchBox__button {
  padding: 0;
  background-color: #460e30;
  color: #ffffff;
  font-family: trade-gothic-next, sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  height: 24px;
  width: 24px;
  transform: translateY(5px);
}

.st0 {
  transition: fill 0.15s linear;
}

.searchBox__button:hover .st0,
.searchBox__button:focus .st0 {
  fill: white;
}

.searchBox__options {
  margin: 0;
  text-align: right;
  padding-top: 0.5rem;
  font-size: 13px;
  color: #f2f2f2;
}

.options__radio {
  position: relative;
}

.options__radio::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #460e30;
  border: 2px solid #f2f2f2;
  border-radius: 100%;
  position: absolute;
  left: -2px;
  top: -2px;
  cursor: pointer;
  transition: border-color 0.1s linear;
  box-sizing: content-box;
}

.options__radio:hover::before {
  border-color: #f2f2f2;
  color: #f2f2f2;
}

.options__radio:hover~.options__radioLabel {
  color: #f2f2f2;
}

#searchBox .options__link a {
  text-decoration: none;
}

.options__radio:checked::before {
  background-color: white;
  border-color: white;
}

.options__radio:checked~.options__radioLabel {
  color: white;
}

.options__radioLabel {
  cursor: pointer;
  transition: color 0.1s linear;
  padding-left: 0.2rem;
  padding-right: 0.35rem;
}

.options__link>a {
  color: #f2f2f2;
}

/*NAVIGATION STYLES*/

ul.dropdown {
  margin: 0 !important;
  padding: 0 !important;
  display: none;
  position: absolute;
  width: 246px;
  z-index: 9999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#forResearchers .dropdown {
  background: #ed8033;
}

#forThePublic .dropdown {
  background: #00a5c9;
}

#forLibrariesAndEducators .dropdown {
  background: #bc0b36;
}

#forLibrariesAndStateAgencies .dropdown {
  background: #771e7e;
}

.dropdown {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

.dropdown li:last-child a {
  border-radius: 0 0 5px 5px;
}

#breadcrumbs {
  max-width: 984px;
  margin: auto;
  padding: 20px 0;
  font-family: trade-gothic-next, sans-serif;
  font-weight: bold;
  font-size: 0.9em;
}

#breadcrumbs .breadcrumbLink {
  color: #6c6c6c;
  transition: 0.2s;
  text-decoration: none;
}

#breadcrumbs .breadcrumbLink:hover {
  text-decoration: none;
  color: #505050;
}

#breadcrumbs .breadcrumbSeparator {
  margin: 0 10px;
  color: #bcbcbc;
}

.socialMediaBar__icon {
  box-sizing: content-box;
}

/*MEDIA QUERIES FOR SMALLER SCREENS*/

@media screen and (max-width: 984px) {
  header {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
  }

  ul.primary-nav {
    width: 100%;
    flex-direction: column;
  }

  ul.primary-nav li {
    width: 100%;
    flex: 1 1 auto;
  }

  ul.primary-nav li a {
    text-align: left;
  }

  ul.dropdown {
    max-width: 100% !important;
  }

  #forResearchers {
    border-bottom: none;
    border-left: 5px solid #ed8033;
  }

  #forThePublic {
    border-bottom: none;
    border-left: 5px solid #00a5c9;
  }

  #forLibrariesAndEducators {
    border-bottom: none;
    border-left: 5px solid #bc0b36;
  }

  #forLibrariesAndStateAgencies {
    border-bottom: none;
    border-left: 5px solid #771e7e;
  }

  #breadcrumbs {
    padding: 20px 15px;
  }
}